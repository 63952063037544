<template>
  <div>
    <div id="container-privacy">
      <b-button
        variant="primary"
        id="back"
        class="rounded-circle px-2 left-top"
        @click="routerGoBack()"
      >
        <b-icon icon="chevron-left" scale="1"></b-icon>
      </b-button>
      <h3>Datenschutzerklärung</h3>
      <h2>Informationen gemäß §§ 5, 6 TMG, 55 II RStV</h2>
      Wir verarbeiten Ihre Daten stets im Einklang mit den gesetzlichen
      Vorschriften, insbesondere mit dem Telemediengesetz, der VERORDNUNG (EU)
      2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016 zum
      Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten,
      zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG
      (Datenschutz-Grundverordnung) (im folgenden kurz DSGVO) und dem
      Bundesdatenschutzgesetz, soweit dieses noch Anwendung findet.

      <br />
      <br />
      <h4>1. Allgemeines</h4>
      (1) Diese Datenschutzerklärung erläutert, welche Informationen bei einem
      Besuch unserer Website erhoben oder gespeichert und wie diese
      weiterverwendet werden. Dieser Erklärung können Sie zudem entnehmen, wie
      Sie die Richtigkeit der persönlichen Angaben, die wir über Sie speichern,
      überprüfen können und wie Sie diese persönlichen Angaben in unserer
      Datenbank löschen, sperren oder aktualisieren.
      <br />
      <br />
      (2) Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich
      nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie
      unserer Inhalte und Leistungen erforderlich ist. Weitergehende Nutzungen
      werden in den nachfolgenden Bestimmungen aufgeführt. Die Verarbeitung
      personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach
      Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen
      eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
      nicht möglich ist und die Verarbeitung der Daten durch gesetzliche
      Vorschriften gestattet ist.
      <br />
      <br />
      (3) Rechtsgrundlage für die Verarbeitung personenbezogener Daten Soweit
      wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung
      der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
      EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.Bei der
      Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines
      Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich
      ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch
      für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
      erforderlich sind.Soweit eine Verarbeitung personenbezogener Daten zur
      Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser
      Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
      Rechtsgrundlage.Für den Fall, dass lebenswichtige Interessen der
      betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung
      personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d
      DSGVO als Rechtsgrundlage.Ist die Verarbeitung zur Wahrung eines
      berechtigten Interesses unseres Unternehmens oder eines Dritten
      erforderlich und überwiegen die Interessen, Grundrechte und
      Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient
      Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
      <br />
      <br />
      (4) Datenlöschung und Speicherdauer Die personenbezogenen Daten der
      betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der
      Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn
      dies durch den europäischen oder nationalen Gesetzgeber in
      unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften,
      denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
      Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen
      vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
      Erforderlichkeit zur weiteren Speicherung der Daten für einen
      Vertragsabschluss oder eine Vertragserfüllung besteht.
      <br />
      <br />
      (5) Weitergabe der personenbezogenen Daten Eine Verarbeitung Ihrer Daten
      durch uns erfolgt auf Servern mit Standort in Deutschland und damit im
      Rahmen des Datenschutzniveaus der EU. Wir weisen allerdings auf eventuell
      bestehende Ausnahmen nach Nr. 3. der Datenschutzerklärung hin, falls
      Drittanbieter-Tools eingesetzt werden, die Ihren Serverstandort im Ausland
      haben.Sollte eine Weitergabe Ihrer Daten an andere Unternehmen oder
      Subunternehmer erfolgen, erfolgt diese nur unter Einhaltung der
      vorliegenden Datenschutzbestimmungen und der gesetzlichen Vorschriften
      sowie zur Erfüllung der vertraglichen Pflichten, z.B. kann ggfls. der
      Provider entsprechende Statistikdaten einsehen.Ohne Ihre ausdrückliche
      Zustimmung übermitteln wir Ihre personenbezogenen Daten nicht an Dritte
      außerhalb des Unternehmens. Externe Dienstleister, die für uns Daten im
      Auftrag verarbeiten, werden vertraglich verpflichtet. Diesen
      Dienstleistern ist es dabei insbesondere untersagt, Ihre Daten zu anderen
      als zu den ursprünglich zugrundeliegenden Zwecken zu verwenden.Über die
      von Ihnen bereitgestellten Daten hinausgehende Daten, insbesondere solche
      Daten, die Sie uns zur reinen Vertragsabwicklung zu internen Zwecken zur
      Verfügung gestellt haben, werden wir Dritten nur bei einer entsprechenden
      gesetzlichen Verpflichtung oder zur Wahrnehmung berechtigter Interessen
      zur Verfügung stellen.Aufgrund gesetzlicher Vorgaben kann es vorkommen,
      dass wir verpflichtet sind, Ihre Daten auch über den Zeitraum Ihrer
      Benutzung unserer Website hinaus – insbesondere für steuerliche Zwecke –
      zu speichern. Wir werden eine Speicherung jedoch stets nur im
      erforderlichen Maße vornehmen und dabei die gesetzlichen Bestimmungen
      berücksichtigen.
      <br />
      <br />
      (6) Speicherort Eine Verarbeitung Ihrer Daten erfolgt auf Servern mit
      Standort in Deutschland und damit im Rahmen des Datenschutzniveaus der EU.
      Wir weisen allerdings auf eventuell bestehende Ausnahmen nach Nr. 3 dieser
      Regelungen.
      <br />
      <br />
      <h4>2. Erhebung von personenbezogenen Daten</h4>
      <h5>a. Erhebung allgemeiner Daten bei Webseitenbesuch</h5>
      (1) Wir speichern bei einem einfachen Websitebesuch lediglich
      Zugriffsdaten im Rahmen so genannter Server-Log-Files. Dies sind Daten,
      welche Ihr Browser zur Verfügung stellt und die ohne Personenbezug sind
      und zwar:- Browsertyp/ -version - Verwendetes Betriebssystem -
      Referrer-URL (die zuvor besuchte Seite) - Websites, die vom System des
      Nutzers über unser Website aufgerufen werden- den
      Internet-Service-Provider des Nutzers- Hostname des zugreifenden Rechners
      (IP-Adresse) - Uhrzeit und Datum der Serveranfrage. Diese Daten sind für
      uns nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser
      Daten mit anderen Datenquellen wird nicht vorgenommen, die Daten werden
      zudem nach einer statistischen Auswertung gelöscht. Hierzu werden die
      Zugriffe der Nutzer auf unsere Webseiten in den Server-Log-Files
      gespeichert, inklusive der IP-Adresse. Diese Log-Files werden zu
      statistischen Zwecken monatlich mit einer Analysesoftware aufbereitet und
      sodann gelöscht. Ein Rückschluss auf eine bestimmte Person ist bei der
      Verwendung der Daten durch uns nicht möglich.
      <br />
      <br />
      (2) Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
      Buchst. f DSGVO. Die berechtigen Interessen ergeben sich zum einen bereits
      aus der Notwendigkeit, die Inhalte der Webseite technisch korrekt
      darzustellen und zu optimieren. Weiter ist die Erhebung notwendig, um die
      Funktionsfähigkeit der Website im Falle von Angriffen Dritter
      sicherzustellen und eine Strafverfolgung derartiger Angriffe zu
      ermöglichen.
      <br />
      <br />
      (3) Die vorübergehende Speicherung der IP-Adresse durch das System ist
      notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu
      ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der
      Sitzung gespeichert bleiben. In diesen Zwecken liegt auch unser
      berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f
      DSGVO.
      <br />
      <br />
      (4) Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
      ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
      Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige
      Sitzung beendet ist.
      <br />
      <br />
      (5) Die Erfassung der Daten zur Bereitstellung der Website und die
      Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite
      zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
      Widerspruchsmöglichkeit.
      <br />
      <br />
      <h5>b. Verarbeitung von Vertragsdaten</h5>
      (1) Wir verarbeiten die Daten unserer Vertragspartner und Interessenten
      sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder
      Vertragspartner (einheitlich bezeichnet als „Vertragspartner“)
      entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegenüber unsere
      vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei
      verarbeiteten Daten, die Art, der Umfang und der Zweck und die
      Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem
      zugrundeliegenden Vertragsverhältnis.
      <br />
      <br />
      (2) Zu den verarbeiteten Daten gehören die Stammdaten unserer
      Vertragspartner (z.B., Namen und Adressen), Kontaktdaten (z.B.
      E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in Anspruch
      genommene Leistungen, Vertragsinhalte, vertragliche Kommunikation, Namen
      von Kontaktpersonen) und Zahlungsdaten (z.B., Bankverbindungen,
      Zahlungshistorie). Zudem verarbeiten wir Bestandsdaten (z.B.,
      Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail,
      Telefonnummern), Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos),
      Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B.,
      Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen
      der Auswertung und Erfolgsmessung von Marketingmaßnahmen) im Rahmen der
      Auftragsabwicklung. Zu den Betroffenen gehören unsere Kunden,
      Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter
      sowie Dritte. Der Zweck der Verarbeitung besteht in der Erbringung von
      Vertragsleistungen, Abrechnung und unserem Kundenservice. Die
      Rechtsgrundlagen der Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b
      DSGVO (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse,
      Statistik, Optimierung, Sicherheitsmaßnahmen).
      <br />
      <br />
      (3) Besondere Kategorien personenbezogener Daten verarbeiten wir
      grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten oder
      vertragsgemäßen Verarbeitung sind.
      <br />
      <br />
      (4) Wir verarbeiten Daten, die zur Begründung und Erfüllung der
      vertraglichen Leistungen erforderlich sind und weisen auf die
      Erforderlichkeit ihrer Angabe, sofern diese für die Vertragspartner nicht
      evident ist, hin. Eine Offenlegung an externe Personen oder Unternehmen
      erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist. Bei der
      Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten handeln
      wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen
      Vorgaben einer Auftragsverarbeitung gem. Art. 28 DSGVO und verarbeiten die
      Daten zu keinen anderen, als den auftragsgemäßen Zwecken.
      <br />
      <br />
      (5) Im Rahmen der Inanspruchnahme unserer Onlinedienste, können wir die
      IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung speichern. Die
      Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als
      auch der Interessen der Nutzer am Schutz vor Missbrauch und sonstiger
      unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
      grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche gem.
      Art. 6 Abs. 1 lit. f. DSGVO erforderlich oder es besteht hierzu eine
      gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.
      <br />
      <br />
      (6) Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
      vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der Daten
      wird alle drei Jahre überprüft; im Fall der gesetzlichen
      Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6 J, gem. §
      257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1 AO). Im Fall von Daten, die uns
      gegenüber im Rahmen eines Auftrags durch den Auftraggeber offengelegt
      wurden, löschen wir die Daten entsprechend den Vorgaben des Auftrags,
      grundsätzlich nach Ende des Auftrags.
      <br />
      <br />
      <h5>c. Datenschutz im Bewerbungsverfahren</h5>
      (1) Bewerberdaten verarbeiten wir ausschließlich zum Zweck und im Rahmen
      des Bewerbungsverfahrens. Die Verarbeitung der Bewerberdaten erfolgt zur
      Erfüllung unserer (vor)vertraglichen Verpflichtungen im Rahmen des
      Bewerbungsverfahrens im Sinne des Art. 6 Abs. 1 lit. b. DSGVO Art. 6 Abs.
      1 lit. f. DSGVO sofern die Datenverarbeitung z.B. im Rahmen von
      rechtlichen Verfahren für uns erforderlich wird, wie dies etwa gemäß § 26
      BDSG der Fall ist.
      <br />
      <br />
      (2) Die notwendigen Bewerberdaten sind, sofern wir ein Onlineformular
      anbieten gekennzeichnet, und ergeben sich im Übrigen aus den
      Stellenbeschreibungen. Grundsätzlich sind folgende Daten für die Erhebung
      und Bewerbung erforderlich: Angaben zur Person, Post- und Kontaktadressen
      und die zur Bewerbung gehörenden Unterlagen, wie Anschreiben, Lebenslauf
      und die Zeugnisse. Daneben können uns Bewerber freiwillig zusätzliche
      Informationen mitteilen.
      <br />
      <br />
      (3) Mit der Übermittlung der Bewerbung an uns, erklären sich die Bewerber
      mit der Verarbeitung ihrer Daten zu Zwecken des Bewerbungsverfahrens
      entsprechend der in dieser Datenschutzerklärung dargelegten Art und Umfang
      einverstanden.
      <br />
      <br />
      (4) Soweit im Rahmen des Bewerbungsverfahrens freiwillig besondere
      Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO
      mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs.
      2 lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B.
      Schwerbehinderteneigenschaft oder ethnische Herkunft). Soweit im Rahmen
      des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten
      im Sinne des Art. 9 Abs. 1 DSGVO bei Bewerbern durch uns angefragt werden,
      erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2 lit. a DSGVO
      (z.B. Gesundheitsdaten, wenn diese für die Berufsausübung erforderlich
      sind).
      <br />
      <br />
      (5) Daten aus einem etwaig bereitgestellten Online-Formular werden
      entsprechend dem Stand der Technik verschlüsselt an uns übertragen. Ferner
      können Bewerber ihre Bewerbungen via E-Mail übermitteln. Hierbei bitten
      wir jedoch zu beachten, dass E-Mails grundsätzlich nicht verschlüsselt
      versendet werden und die Bewerber selbst für die Verschlüsselung sorgen
      müssen. Alternativ ist eine Zusendung der Bewerbung über den Postweg
      möglich.
      <br />
      <br />
      (6) Die von den Bewerbern zur Verfügung gestellten Daten, können im Fall
      einer erfolgreichen Bewerbung für die Zwecke des
      Beschäftigungsverhältnisses durch uns weiterverarbeitet werden.
      Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht erfolgreich
      ist, werden die Daten der Bewerber gelöscht. Die Daten der Bewerber werden
      ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen wird, wozu die
      Bewerber jederzeit berechtigt sind. Die Löschung erfolgt, vorbehaltlich
      eines berechtigten Widerrufs der Bewerber, nach dem Ablauf eines Zeitraums
      von sechs Monaten, damit etwaige Anschlussfragen zu der Bewerbung
      beantwortet werden und Nachweispflichten aus dem Gleichbehandlungsgesetz
      erfüllt werden können. Rechnungen über etwaige Reisekostenerstattung
      werden entsprechend den steuerrechtlichen Vorgaben archiviert.
      <br />
      <br />
      <h5>d. Hosting und E-Mail-Versand</h5>
      (1) Folgende Leistungen stellen wir unter Inanspruchnahme von
      Hosting-Leistungen zur Verfügung: Infrastruktur- und
      Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
      Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
      Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes
      einsetzen.
      <br />
      <br />
      (2) Dabei werden personenbezogene Daten folgender Kategorien durch uns und
      unseren Hosting-Partner erhoben: Bestandsdaten, Kontaktdaten,
      Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten
      von Kunden, Interessenten und Besuchern dieses Onlineangebotes. Grundlage
      der Datenerhebung sind die Wahrnehmung unseres berechtigten Interesses
      bezüglich der dauerhaften Verfügbarkeit sowie stetigen Verbesserung des
      Onlineangebots gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO im
      Rahmen der Auftragsverarbeitung.
      <br />
      <br />
      <h5>e. Betriebswirtschaftliche Analysen und Marktforschung</h5>
      Wir analysieren die von uns gemäß Art. 6 Abs. 1 lit. f DSGVO erhobenen
      Daten zu Geschäftsvorgängen, Verträgen und Anfragen, um Markttendenzen zu
      erkennen und Vorstellungen von Vertragspartnern und weiteren Nutzern
      umsetzen zu können. Zu den betroffenen Personengruppen gehören die
      jeweiligen Vertragspartner nebst Interessenten, sonstigen Kunden, sowie
      die Benutzer unserer Onlineangebots.
      <br />
      <br />
      Dabei können insbesondere die Profile inklusive der dort gemachten Angaben
      der registrierten Nutzer dazu verwendet werden, Analysen im Bereich
      Marketing und Marktforschung durchzuführen. Diese Analysen dienen der
      Steigerung unserer Performance in den Bereichen Kundenzufriedenheit und
      Wirtschaftlichkeit und werden nur anonymisiert und zusammengefasst an
      externe juristische sowie natürliche Personen offenbart. Mit der Kündigung
      des jeweiligen Nutzers werden auch diese Analysen anonymisiert oder
      gelöscht, spätestens jedoch zwei Jahre nach Vertragsschluss. Das
      Unternehmen wirkt auf eine anonyme Erstellung von betriebswirtschaftlichen
      Analysen und Tendenzbestimmungen hin.
      <br />
      <br />
      <h4>3. Third Party Plug-Ins / Datenverarbeitung bei Dritten</h4>
      <h5>(1) Datenschutzerklärung für die Nutzung von Google Analytics</h5>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA
      94043, USA. Google Analytics verwendet so genannte "Cookies". Das sind
      Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
      Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden
      in der Regel an einen Server von Google in den USA übertragen und dort
      gespeichert.
      <br />
      <br />
      <h5>IP Anonymisierung</h5>
      Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
      Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
      Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
      Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur
      in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
      den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
      Website wird Google diese Informationen benutzen, um Ihre Nutzung der
      Website auszuwerten, um Reports über die Websiteaktivitäten
      zusammenzustellen und um weitere mit der Websitenutzung und der
      Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber
      zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
      übermittelte IP- Adresse wird nicht mit anderen Daten von Google
      zusammengeführt.
      <br />
      <br />
      <h5>Browser Plugin</h5>
      Sie können die Speicherung der Cookies durch eine entsprechende
      Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
      darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website vollumfänglich werden nutzen können. Sie können
      darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre
      Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
      sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das
      unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
      installieren: https://tools.google.com/dlpage/gaoptout?hl=de
      <br />
      <br />
      <h5>Widerspruch gegen Datenerfassung</h5>
      Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
      indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt,
      der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website
      verhindert: Google Analytics deaktivieren.“Mehr Informationen zum Umgang
      mit Nutzerdaten bei Google Analytics finden Sie in der
      Datenschutzerklärung von Google:
      https://support.google.com/analytics/answer/6004245?hl=de
      <br />
      <br />
      <h5>Auftragsdatenverarbeitung</h5>
      Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
      abgeschlossen und setzen die strengen Vorgaben der deutschen
      Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
      <br />
      <br />
      <h5>Demografische Merkmale bei Google Analytics</h5>
      Diese Website nutzt die Funktion “demografische Merkmale” von Google
      Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter,
      Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten
      stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten
      von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet
      werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen
      in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
      Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
      dargestellt generell untersagen.
      <br />
      <br />
      <h5>(2) Zielgruppenbildung mit Google Analytics</h5>
      Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten
      Googles und seiner Partner geschalteten Anzeigen, nur solchen Nutzern
      anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben
      oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder
      Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen,
      die wir an Google übermitteln (sog. „Remarketing-“, bzw.
      „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchten
      wir auch sicherstellen, dass unsere Anzeigen dem potentiellen Interesse
      der Nutzer entsprechen. Die Nutzung der Funktion erfolgt insoweit aus
      berechtigtem Interesse, Art. 6 Abs. 1 lit. f. DSGVO.
      <br />
      <br />
      <h5>(3) Google Maps</h5>
      Zur Darstellung von Karten nutzen wir den Dienst “Google Maps” des
      Anbieters Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
      Irland. Zur Darstellung dieser Karten werden im Regelfall die IP-Adresse
      und der Standort des Nutzers abgefragt. Grundlage dieser Verarbeitung ist
      die ausdrückliche Einwilligung des Nutzers gem. Art. 6 Abs. 1 lit. a
      DSGVO, z.B. in dem auf dem Mobilgerät die Standortfreigabe aktiviert und
      Google Maps dazu ermächtigt wird, den Standort bei der Verwendung der App
      zu benutzt. Die Daten können in den USA verarbeitet werden.
      Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out:
      https://adssettings.google.com/authenticated.
      <br />
      <br />
      <h5>(4) Berechtigtes Interesse</h5>
      Der Einsatz der o.g. Plug-Ins bzw. die Einbindung von Datenverarbeitungen
      bei Drittparteien wurde in datenschutzrechtlicher Hinsicht überprüft und
      erfolgt auf Basis von Art. 6 f) DSGVO zur Wahrung berechtigter Interessen,
      zur Verbesserung unseres Webangebotes.
      <br />
      <br />
      <h4>4. Cookies</h4>
      (1) Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
      Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
      Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
      Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
      gespeichert werden. Dieser Cookie enthält eine charakteristische
      Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
      erneuten Aufrufen der Website ermöglicht.
      <br />
      <br />
      (2) Es existieren Cookies, die nach Ende der Browsersitzung wieder
      gelöscht werden (sogenannte Session-ID-Cookies). Die Cookies werden zum
      Zwecke der Autorisierung, Identifizierung und zum Erhalt spezieller
      Informationen wie zum Beispiel der Information, ob Sie eingeloggt bleiben
      möchten, genutzt. Die Cookies werden nach einer Stunde automatisch wieder
      gelöscht.
      <br />
      <br />
      (3) Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
      Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
      aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam
      mit sonstigen personenbezogenen Daten der Nutzer gespeichert.
      <br />
      <br />
      (4) Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner
      über die Verwendung von Cookies zu Analysezwecken informiert und auf diese
      Datenschutzerklärung verwiesen bzw. im notwendigen Fall eine Einwilligung
      zur Verarbeitung von personenbezogenen Daten in Verbindung mit Cookies
      eingeholt. Es erfolgt in diesem Zusammenhang auch ein Hinweis darauf, wie
      die Speicherung von Cookies in den Browsereinstellungen unterbunden werden
      kann.
      <br />
      <br />
      (5) Wenn darüber hinaus Cookies auf Ihrem PC abgelegt werden, haben Sie
      die Kontrolle darüber, ob und wann diese Cookies gelöscht werden. Bitte
      verwenden Sie hierzu die entsprechende Funktion in Ihrem Browser.
      <br />
      <br />
      (6) Bei den meisten Internetbrowsern können Sie Cookies von der Festplatte
      löschen, sperren oder erhalten eine Warnung bevor ein Cookie hinterlegt
      wird. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
      Cookies informiert werden, von Fall zu Fall über die Annahme entscheiden
      oder die Annahme von Cookies grundsätzlich ausschließen. Bei der
      Nichtannahme von Cookies kann die Funktionalität unserer Website
      eingeschränkt sein. Bitte informieren Sie sich im Benutzerhandbuch Ihres
      Browsers oder bei dem Hersteller des Browsers darüber, wie die Programme
      entsprechend eingestellt werden.
      <br />
      <br />
      (7) Wir werden solche automatisch gespeicherten Informationen nur mit
      Ihrer vorherigen Einwilligung mit den personenbezogenen Daten in
      Verbindung bringen, die Sie uns zuvor (z.B. bei der Registrierung) auf
      unseren Websites zur Verfügung gestellt haben.
      <br />
      <br />
      (8) Die Nutzung von Daten aus gesetzten Cookies, insbesondere auch in
      Verbindung mit den o.g. Cookies durch externe Dienste (Nr. 3), erfolgt auf
      Basis von Art. 6 f) DSGVO zur Wahrung berechtigter Interessen, wobei wir
      davon ausgehen, dass Ihre Interessen, Grundrechte und Grundfreiheiten
      hierdurch nicht eingeschränkt werden, da personenbezogene Daten hierbei
      weder durch uns noch durch Dritte gewonnen werden. Vielmehr handelt es
      sich um statistische Daten, die zwar Ihrem Nutzerverhalten und ggfls.
      weiteren Faktoren angepasst sind bzw. diese Preis geben, nicht aber Daten,
      die zu einer individuellen Identifizierung führen können.
      <br />
      <br />
      <h4>5. Datensicherheit</h4>
      (1) Wir sichern unsere Websites und die damit verbundenen Systeme durch
      technische und organisatorische Maßnahmen gegen Verlust, Zerstörung,
      Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte
      Personen.
      <br />
      <br />
      (2) Sie sollten Ihre Zugangsinformationen stets vertraulich behandeln und
      das Browserfenster schließen, wenn Sie die Nutzung beendet haben,
      insbesondere wenn Sie den Computer gemeinsam mit anderen nutzen, um einem
      Missbrauch Ihres Accounts vorzubeugen.
      <br />
      <br />
      (3) Wir haften nicht für die Inhalte anderer Anbieter, die über die
      Hyperlinks auf unseren Websites erreicht werden können. Links auf unserer
      Website verweisen auf Inhalte, die nicht auf eigenen Servern gespeichert
      sind. Externe Inhalte wurden beim Setzen von Links auf Rechtswidrigkeit
      und Strafbarkeit geprüft. Dennoch ist nicht auszuschließen, dass Inhalte
      im Nachhinein von Anbietern verändert werden.
      <br />
      <br />
      <h4>6. Kontaktformular / E-Mail</h4>
      (1) Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
      Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.
      <br />
      <br />
      (2) Alternativ ist eine Kontaktaufnahme über die bereitgestellte
      E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail
      übermittelten personenbezogenen Daten des Nutzers gespeichert.
      <br />
      <br />
      (3) Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
      Dritte. Die Daten werden ausschließlich für die Verarbeitung der
      Konversation verwendet.
      <br />
      <br />
      (4) Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
      Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
      <br />
      <br />
      (5) Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
      Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f
      DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so
      ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
      DSGVO.
      <br />
      <br />
      (6) Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
      dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
      Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte
      Interesse an der Verarbeitung der Daten.Die sonstigen während des
      Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen
      Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer
      informationstechnischen Systeme sicherzustellen.Die Daten werden gelöscht,
      sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
      erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske
      des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist
      dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet
      ist. Beendet ist die Konversation dann, wenn sich aus den Umständen
      entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.
      <br />
      <br />
      (7) Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
      Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
      <br />
      <br />
      (8) Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
      Verarbeitung der personenbezogenen Daten durch Mitteilung per E-Mail oder
      Post an die verantwortliche Stelle (s.u.) zu widerrufen. Nimmt der Nutzer
      per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
      personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall
      kann die Konversation nicht fortgeführt werden. Alle personenbezogenen
      Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in
      diesem Fall gelöscht.
      <br />
      <br />
      <h4>7. Newsletterdaten</h4>
      (1) Mit den nachfolgenden Hinweisen informieren wir Sie über die Inhalte
      unseres Newsletters sowie das Anmelde-, Versand- und das statistische
      Auswertungsverfahren sowie Ihre Widerspruchsrechte auf. Indem Sie unseren
      Newsletter abonnieren, erklären Sie sich mit dem Empfang und den
      beschriebenen Verfahren einverstanden.
      <br />
      <br />
      (2) Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und weitere
      elektronische Benachrichtigungen mit werblichen Informationen (nachfolgend
      „Newsletter“) nur mit der Einwilligung der Empfänger oder einer
      gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter
      dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung
      der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter
      Informationen zu unseren Produkten und sie begleitenden Informationen
      (z.B. Sicherheitshinweise), Angeboten, Aktionen und unser Unternehmen.
      <br />
      <br />
      (3) Double-Opt-In und Protokollierung: Die Anmeldung zu unserem Newsletter
      erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie erhalten nach der
      Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung
      gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit
      fremden E-Mailadressen anmelden kann. Die Anmeldungen zum Newsletter
      werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen
      Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des
      Anmelde- und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso
      werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten
      Daten protokolliert.
      <br />
      <br />
      (4) Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es aus,
      wenn Sie Ihre E-Mailadresse angeben. Optional bitten wir Sie einen Namen,
      zwecks persönlicher Ansprache im Newsletters anzugeben.
      <br />
      <br />
      (5) Der Versand des Newsletters und die mit ihm verbundene Erfolgsmessung
      erfolgen auf Grundlage einer Einwilligung der Empfänger gem. Art. 6 Abs. 1
      lit. a, Art. 7 DSGVO oder falls eine Einwilligung nicht erforderlich ist,
      auf Grundlage unserer berechtigten Interessen am Direktmarketing gem. Art.
      6 Abs. 1 lt. f. DSGVO i.V.m. § 7 Abs. 3 UWG.
      <br />
      <br />
      (6) Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
      unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser
      Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie
      sicheren Newslettersystems, das sowohl unseren geschäftlichen Interessen
      dient, als auch den Erwartungen der Nutzer entspricht und uns ferner den
      Nachweis von Einwilligungen erlaubt.
      <br />
      <br />
      (7) Kündigung/Widerruf - Sie können den Empfang unseres Newsletters
      jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen. Einen Link zur
      Kündigung des Newsletters finden Sie am Ende eines jeden Newsletters. Wir
      können die ausgetragenen E-Mailadressen bis zu drei Jahren auf Grundlage
      unserer berechtigten Interessen speichern bevor wir sie löschen, um eine
      ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung
      dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen
      beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich,
      sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.
      <br />
      <br />
      <h4>8. SSL-Verschlüsselung</h4>
      Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
      Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie
      an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
      verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
      Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol
      in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können
      die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
      werden.
      <br />
      <br />
      <h4>9. Onlinepräsenzen in sozialen Medien</h4>
      Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
      Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
      kommunizieren und sie dort über Neuigkeiten und unsere Leistungen
      informieren zu können. Wir weisen darauf hin, dass dabei Daten der Nutzer
      außerhalb des Raumes der Europäischen Union verarbeitet werden können.
      Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die
      Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im Hinblick
      auf US-Anbieter die unter dem Privacy-Shield zertifiziert sind, weisen wir
      darauf hin, dass diese insoweit verpflichtet sind, die
      Datenschutzstandards der EU einzuhalten. Im Regelfall werden in sozialen
      Netzwerken Nutzerdaten für Marktforschungs- und Werbezwecke verarbeitet.
      So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden
      Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile
      können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und
      außerhalb der Plattformen zu schalten, die mutmaßlich den Interessen der
      Nutzer entsprechen. Zu diesen Zwecken werden so genannte Cookies auf den
      Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
      Interessen der Nutzer gespeichert werden. Ferner können in den
      Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten
      Geräte gespeichert werden (insbesondere wenn die Nutzer Mitglieder der
      jeweiligen Plattformen sind und bei diesen eingeloggt sind). Die
      Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage
      unserer berechtigten Interessen an einer effektiven Information der Nutzer
      und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls
      die Nutzer von den jeweiligen Anbietern der Plattformen um eine
      Einwilligung in die vorbeschriebene Datenverarbeitung gebeten werden, ist
      die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
      Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der
      Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die nachfolgend
      verlinkten Angaben der Anbieter. Auskunftsanfragen und Nutzerrechte können
      am effektivsten gegenüber den Anbietern geltend gemacht werden. Nur diese
      haben Zugriff auf alle relevanten Daten der Nutzer und können direkt
      entsprechende Maßnahmen ergreifen und Auskünfte geben. Für Fragen stehen
      wir Ihnen gern zur Verfügung.
      <br />
      <br />
      <h4>10. Microsoft Cloud-Dienste</h4>
      (1) Die von Microsoft bereitgestellte Cloud inklusive Softwaredienste wird
      für die Speicherung von Dokumenten sowie deren Verwaltung, die
      Kalenderverwaltung, den Versand und die Speicherung von E-Mails, die
      Erstellung von Tabellen und Präsentationen sowie für den schnellen
      Austausch von Dateien unterschiedlichsten Inhalts genutzt. Hierbei werden
      die personenbezogenen Daten der Nutzer verarbeitet, soweit diese
      Bestandteil der innerhalb der beschriebenen Dienste verarbeiteten
      Dokumente und Inhalte werden oder Teil von Kommunikationsvorgängen sind.
      Hierzu können z.B. Stammdaten und Kontaktdaten der Nutzer, Daten zu
      Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören.
      Microsoft verarbeitet ferner Nutzungsdaten und Metadaten, die von
      Microsoft zu Sicherheitszwecken und Serviceoptimierung verwendet werden.
      <br />
      <br />
      (2) Mit der Nutzung der Microsoft Cloud zum öffentlichen Teilen von
      Dokumenten, Webseiten oder sonstigen Inhalte erklärt sich der Nutzer
      einverstanden, dass auf dem jeweiligen Endgerät Datenfragmente gespeichert
      werden, die Werbezwecken dienen oder die Nutzereinstellungen festhalten
      (sog. „Cookies“).
      <br />
      <br />
      (3) Die Nutzung der Cloud und Softwaredienste erfolgt gem. Art. 6 Abs. 1
      lit. f DSGVO. Berechtigte Interessen der Nutzung sind die Optimierung und
      Digitalisierung von Verwaltungsaufgaben sowie die schnelle interne
      Kommunikation. Weitere Hinweise erhalten Sie in der Datenschutzerklärung
      von Microsoft (https://privacy.microsoft.com/de-de/privacystatement) und
      den Sicherheitshinweisen zu Microsoft Cloud-Diensten
      (https://www.microsoft.com/de-de/trustcenter). Sie können der Verarbeitung
      Ihrer Daten in der Microsoft Cloud uns gegenüber entsprechend den
      gesetzlichen Vorgaben widersprechen. Im Übrigen bestimmt sich die Löschung
      der Daten innerhalb von Microsofts Cloud-Diensten nach den übrigen
      Verarbeitungsprozessen in deren Rahmen die Daten verarbeitet werden (z.B.,
      Löschung für Vertragszwecke nicht mehr erforderlicher oder Speicherung für
      Zwecke der Besteuerung erforderlicher Daten).
      <br />
      <br />
      (4) Die Microsoft Cloud-Dienste werden von Microsoft Corporation, One
      Microsoft Way, Redmond, WA 98052-6399 USA angeboten. Soweit eine
      Verarbeitung von Daten in die USA stattfindet, verweisen wir auf die
      Zertifizierung von Microsoft unter dem Privacy Shield
      (https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&status=Active).
      <br />
      <br />
      <h4>11. Ihre Rechte</h4>
      Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener
      i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem
      Verantwortlichen zu:
      <br />
      <br />
      <h5>a. Auskunftsrecht</h5>
      Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
      personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
      Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen
      über folgende Informationen Auskunft verlangen:
      <br />
      <br />
      (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
      <br />
      <br />
      (2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
      <br />
      <br />
      (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die
      Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch
      offengelegt werden;
      <br />
      <br />
      (4) die geplante Dauer der Speicherung der Sie betreffenden
      personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich
      sind, Kriterien für die Festlegung der Speicherdauer;
      <br />
      <br />
      (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
      betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der
      Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
      gegen diese Verarbeitung;
      <br />
      <br />
      (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
      <br />
      <br />
      (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die
      personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
      <br />
      <br />
      (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich
      Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen
      Fällen – aussagekräftige Informationen über die involvierte Logik sowie
      die Tragweite und die angestrebten Auswirkungen einer derartigen
      Verarbeitung für die betroffene Person.Ihnen steht das Recht zu, Auskunft
      darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in
      ein Drittland oder an eine internationale Organisation übermittelt werden.
      In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien
      gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu
      werden.
      <br />
      <br />
      <h5>b. Recht auf Berichtigung</h5>
      Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber
      dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten,
      die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche
      hat die Berichtigung unverzüglich vorzunehmen.
      <br />
      <br />
      <h5>c. Recht auf Einschränkung der Verarbeitung</h5>
      Unter den folgenden Voraussetzungen können Sie die Einschränkung der
      Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
      <br />
      <br />
      (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für
      eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
      Richtigkeit der personenbezogenen Daten zu überprüfen;
      <br />
      <br />
      (2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der
      personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
      Nutzung der personenbezogenen Daten verlangen;
      <br />
      <br />
      (3) der Verantwortliche die personenbezogenen Daten für die Zwecke der
      Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung,
      Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
      <br />
      <br />
      (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
      eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des
      Verantwortlichen gegenüber Ihren Gründen überwiegen.Wurde die Verarbeitung
      der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese
      Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder
      zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder
      zum Schutz der Rechte einer anderen natürlichen oder juristischen Person
      oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder
      eines Mitgliedstaats verarbeitet werden.Wurde die Einschränkung der
      Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von
      dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
      <br />
      <br />
      <h5>d. Recht auf Löschung</h5>
      LöschungspflichtSie können von dem Verantwortlichen verlangen, dass die
      Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und
      der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen,
      sofern einer der folgenden Gründe zutrifft:
      <br />
      <br />
      (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für
      die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
      notwendig.
      <br />
      <br />
      (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
      Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt
      an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
      <br />
      <br />
      (3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung
      ein und es liegen keine vorrangigen berechtigten Gründe für die
      Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch
      gegen die Verarbeitung ein.
      <br />
      <br />
      (4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
      verarbeitet.
      <br />
      <br />
      (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
      Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem
      Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
      unterliegt.
      <br />
      <br />
      (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
      angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO
      erhoben. Information an DritteHat der Verantwortliche die Sie betreffenden
      personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1
      DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung
      der verfügbaren Technologie und der Implementierungskosten angemessene
      Maßnahmen, auch technischer Art, um für die Datenverarbeitung
      Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu
      informieren, dass Sie als betroffene Person von ihnen die Löschung aller
      Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
      dieser personenbezogenen Daten verlangt haben.
      <br />
      <br />
      <h5>Ausnahmen</h5>
      Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich
      ist
      <br />
      <br />
      (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
      <br />
      <br />
      (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
      nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche
      unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
      öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
      die dem Verantwortlichen übertragen wurde;
      <br />
      <br />
      (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
      Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
      <br />
      <br />
      (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche
      oder historische Forschungszwecke oder für statistische Zwecke gem. Art.
      89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht
      voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich
      macht oder ernsthaft beeinträchtigt, oder
      <br />
      <br />
      (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      <br />
      <br />
      <h5>e. Recht auf Unterrichtung</h5>
      Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
      Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
      verpflichtet, allen Empfängern, denen die Sie betreffenden
      personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
      Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei
      denn, dies erweist sich als unmöglich oder ist mit einem
      unverhältnismäßigen Aufwand verbunden.Ihnen steht gegenüber dem
      Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
      werden.
      <br />
      <br />
      <h5>f. Recht auf Datenübertragbarkeit</h5>
      Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie
      dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
      gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das
      Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch
      den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
      wurden, zu übermitteln, sofern
      <br />
      <br />
      (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
      DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6
      Abs. 1 lit. b DSGVO beruht und
      <br />
      <br />
      (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.In
      Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die
      Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen
      einem anderen Verantwortlichen übermittelt werden, soweit dies technisch
      machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
      beeinträchtigt werden.Das Recht auf Datenübertragbarkeit gilt nicht für
      eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer
      Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in
      Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
      wurde.
      <br />
      <br />
      <h5>g. Widerspruchsrecht</h5>
      Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation
      ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
      personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
      DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
      Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet die Sie
      betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann
      zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
      Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient
      der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.Werden
      die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung
      zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
      Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
      derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es
      mit solcher Direktwerbung in Verbindung steht.Widersprechen Sie der
      Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
      personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.Sie haben
      die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
      Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr
      Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
      technische Spezifikationen verwendet werden.
      <br />
      <br />
      <h5>
        h. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
      </h5>
      Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
      jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
      Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
      Verarbeitung nicht berührt.
      <br />
      <br />
      <h5>
        i. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
      </h5>
      Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
      Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
      unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
      oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht,
      wenn die Entscheidung
      <br />
      <br />
      (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
      dem Verantwortlichen erforderlich ist,
      <br />
      <br />
      (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
      denen der Verantwortliche unterliegt, zulässig ist und diese
      Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
      Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
      <br />
      <br />
      (3) mit Ihrer ausdrücklichen Einwilligung erfolgt.Allerdings dürfen diese
      Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten
      nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g
      DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten
      sowie Ihrer berechtigten Interessen getroffen wurden.Hinsichtlich der in
      (1) und (3) genannten Fälle trifft der Verantwortliche angemessene
      Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen
      zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer
      Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts
      und auf Anfechtung der Entscheidung gehört.
      <br />
      <br />
      <h5>j. Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
      Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
      Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer
      Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
      ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn
      Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
      personenbezogenen Daten gegen die DSGVO verstößt. Die Aufsichtsbehörde,
      bei der die Beschwerde eingereicht wurde, unterrichtet den
      Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde
      einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art.
      78 DSGVO. Die zuständige Aufsichtsbehörde ist die
      <br />
      <br />
      Landesbeauftragte für Datenschutz und Informationsfreiheit
      Nordrhein-Westfalen Helga Block Postfach 20 04 44 40102 Düsseldorf
      <br />
      <br />
      oder:
      <br />
      <br />
      Kavalleriestraße 2-4 40213 Düsseldorf
      <br />
      <br />
      <h4>12. Ansprechpartner für Datenschutz</h4>
      Die verantwortliche Stelle im Sinne der DSGVO ist die
      <br />
      <br />
      digital animals GmbH, Katernberger Straße 107, 45327 Essen
      <br />
      <br />
      Vertreten durch die Geschäftsführer Daniel Terstegge, David van den Boom
      und Torsten Baschwitz
      <br />
      <br />
      Email: bee@digtalanimals.de
      <br />
      Fon: +49 (0) 178 2654 355
      <br />
      <br />
      <h4>13. Änderungen dieser Erklärung</h4>
      Wir können diese Datenschutzerklärung gelegentlich im Hinblick auf
      rechtliche, technische oder geschäftliche Entwicklungen aktualisieren.
      Wenn wir unsere Datenschutzerklärung aktualisieren, werden wir angemessene
      Maßnahmen treffen, um Sie je nach Wichtigkeit der vorgenommenen Änderungen
      zu informieren. Wir werden für jede wesentliche Änderung der
      Datenschutzerklärung, die wir vornehmen, Ihre Einwilligung einholen, wenn
      und soweit dies gemäß den einschlägigen Datenschutzgesetzen erforderlich
      ist. Das Datum der „letzten Aktualisierung“ finden Sie am Ende dieser
      Datenschutzerklärung.
      <br />
      <br />
      Letzte Aktualisierung: 01.03.2021
      <br />
      <br />
    </div>
    <div id="back-to-top-button" class="right-bottom opacity-zero">
      <b-button
        variant="primary"
        id="back-to-top"
        class="rounded-circle px-2"
        @click="scrollToTop"
      >
        <b-icon icon="chevron-up" scale="1"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import store from '../store'
export default {
  name: 'Privacy',
  activated () {
    // console.log("terms activated");
    // TODO Why is it only working with this hack?
    // window.scrollTo(0, 1);
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 500);
    this.active = true
  },
  data () {
    return {
      active: false
    }
  },
  mounted () {
    const that = this
    document.addEventListener('scroll', function (e) {
      // console.log(window.scrollY , window.innerHeight);
      if (that.active) {
        if (window.scrollY > window.innerHeight / 3) {
          document
            .getElementById('back-to-top-button')
            .classList.remove('opacity-zero')
        } else {
          document
            .getElementById('back-to-top-button')
            .classList.add('opacity-zero')
        }
      }
    })
  },
  deactivated () {
    this.active = false
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    routerGoBack () {
      if (this.fullscreenState) {
        this.toggleFullscreen()
      }
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    }
  }
}
</script>
<style scoped lang="scss">
#back-to-top {
  pointer-events: auto;
}
.right-bottom {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 250;
  justify-content: flex-end;
}
.opacity-zero {
  opacity: 0;

  transition: all 0.5s !important;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
}
#container-privacy {
  width: 100vw;
  height: 100vh;
  position: absolute;
  text-align: left;
  padding: 100px;
  margin-bottom: 100px;
  top: 0;
}
.left-top {
  position: fixed;
  top: 100px;
  left: 40px;
  z-index: 25;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
</style>
